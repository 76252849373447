@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-family: "Ubuntu", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: black;
  scroll-behavior: smooth;
  

}

*{
box-sizing: border-box;
}

.body{
  height: auto;
}

p {
  /* font-size: 16px; */
}

.no {
  font-family: "Days One", sans-serif;
}


._0shine {
  background: rgba(255, 255, 255, 0.01);
  /* opacity: 0.8; */
  box-shadow: inset 0px 39px 56px -36px rgba(0, 0, 0, 0),
    inset 0px 7px 11px -4px #ffffff,
    inset 0px -82px 68px -64px rgba(243, 242, 242, 0.3),
    inset 0px 98px 100px -48px rgba(141, 141, 141, 0.3),
    inset 0px 4px 18px rgba(154, 146, 210, 0.3),
    inset 0px 1px 0px rgba(227, 222, 255, 0.2);
  /* backdrop-filter: blur(7.5px); */
  border-radius: 51px;
}

._0shine2 {
  background: rgba(255, 255, 255, 0.01);
  /* opacity: 0.8; */
  box-shadow: inset 0px 39px 56px -36px rgba(0, 0, 0, 0),
    inset 0px 7px 11px -4px #ffffff,
    inset 0px -82px 68px -64px rgba(243, 242, 242, 0.3),
    inset 0px 98px 100px -48px rgba(141, 141, 141, 0.3),
    inset 0px 4px 18px rgba(154, 146, 210, 0.3),
    inset 0px 1px 0px rgba(227, 222, 255, 0.2);
  /* backdrop-filter: blur(7.5px); */
}

.accordion {
  /* background-color: #eee; */
  color: rgb(117, 174, 51);
  cursor: pointer;
  padding: 18px;
  margin-top: 40px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 20px;
  transition: 0.4s;
  font-weight: 700;
  text-align: center;
}

.active,
.accordion:hover {
  background-color: rgba(204, 204, 204, 0.091);
}

.panel {
  padding: 16px 18px;
  display: none;
  background-color: black;
  overflow: hidden;
  color: white;
}

.done {
  /* width: 404px; */
  height: 404px;
  /* flex-shrink: 0;

  position: relative;
  padding: 10px; */
  background-image: url("../public/img/circle.png");
  background-repeat: no-repeat;
  margin: auto;
}

/* .drop-shadow {
  padding: 5px;
  margin: 10px;
  border-radius: 450px;
  opacity: 0.8;
  background: rgba(255, 255, 255, 0.01);
  box-shadow: 0px 1px 0px 0px rgba(227, 222, 255, 0.20) inset, 0px 4px 18px 0px rgba(154, 146, 210, 0.30) inset, 0px 98px 100px -48px rgba(141, 141, 141, 0.30) inset, 0px -82px 68px -64px rgba(243, 242, 242, 0.30) inset, 0px 7px 11px -4px #FFF inset, 0px 39px 56px -36px rgba(255, 255, 255, 0.50) inset;
  backdrop-filter: blur(7.5px);
} */

@media (max-width: 768px) {
  .done {
    /* Adjust properties for smaller screens */
    width: 200px; /* Make the element full width */
    height: 200px; /* Adjust height as needed */
    /* Adjust padding for smaller screens */

  }
  
}
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 20px;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  background-color: white;
  border-radius: 20px;
  color: black;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
